import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import tooltip from "@ryangjchandler/alpine-tooltip";
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import containerimages from './containerimages.js';

import intersectClass from 'alpinejs-intersect-class';

Alpine.plugin(intersectClass)
Alpine.plugin(intersect);
Alpine.plugin(tooltip);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(containerimages);

window.Alpine = Alpine
Alpine.start();





document.addEventListener('DOMContentLoaded', function() {

// Cart

// ************************************************
// Shopping Cart API
// ************************************************

    global.shoppingCart = function() {
        // =============================
        // Private methods and propeties
        // =============================
        global.cart = [];

        // Constructor   - row.ART_ID,row.ART_NAME, row.d1, row.d2, row.a, row.SDR
        function Item(id,name,d1,d2,a,sdr,count) {
            this.id = id;
            this.name = name;
            this.d1 = d1;
            this.d2 = d2;
            this.a = a;
            this.sdr = sdr;
            this.count = count;
        }

        // Save cart
        function saveCart() {
            localStorage.setItem('shoppingCart', JSON.stringify(cart));
        }

        // Load cart
        function loadCart() {
            cart = JSON.parse(localStorage.getItem('shoppingCart'));
        }
        if (localStorage.getItem("shoppingCart") != null) {
            loadCart();
        }



        // =============================
        // Public methods and propeties
        // =============================
        var obj = {};

        // Add to cart
        obj.addItemToCart = function(id,name,d1,d2,a,sdr,count) {
            for(var item in cart) {
                if(cart[item].id === id) {
                    if(cart[item].count === 0) {
                        cart[item].count++;
                    }
                    saveCart();
                    return;
                }
            }
            var item = new Item(id,name,d1,d2,a,sdr,count);
            cart.push(item);
            saveCart();
        };
        // Set count from item
        obj.setCountForItem = function(id,name,d1,d2,a,sdr,count) {
            for(var i in cart) {
                if (cart[i].id === id) {
                    cart[i].count = count;
                    break;
                }
            }
        };
        // Remove item from cart
        obj.removeItemFromCart = function(id) {
            for(var item in cart) {
                if(cart[item].id === id) {
                    cart[item].count--;
                    if(cart[item].count === 0) {
                        cart.splice(item, 1);
                    }
                    break;
                }
            }
            saveCart();
        };

        // Remove all items from cart
        obj.removeItemFromCartAll = function(id) {
            for(var item in cart) {
                if(cart[item].id === id) {
                    cart.splice(item, 1);
                    break;
                }
            }
            saveCart();
        };

        // Clear cart
        obj.clearCart = function() {
            cart = [];
            saveCart();
        };

        // Count cart
        obj.totalCount = function() {
            var totalCount = 0;
            for(var item in cart) {
                totalCount += cart[item].count;
            }
            localStorage.setItem('totalCount', totalCount);


            const merkzettel_anzeige = document.querySelector('#merkzettel_anzeige');
            const merkzettel_leer = document.querySelector('#merkzettel_leer');
            if(totalCount >= 1) {
                merkzettel_anzeige.style.display = 'block';
                merkzettel_leer.style.display = 'none';
            } else {
                merkzettel_anzeige.style.display = 'none';
                merkzettel_leer.style.display = 'block';
            }



            return totalCount;
        };

        // Total cart
        obj.totalCart = function() {
            var totalCart = 0;
            for(var item in cart) {
                totalCart += cart[item].id * cart[item].count;
            }
            return Number(totalCart.toFixed(2));
        };

        // List cart
        obj.listCart = function() {
            var cartCopy = [];
            for(var i in cart) {
                var item = cart[i];
                var itemCopy = {};
                for(var p in item) {
                    itemCopy[p] = item[p];
                }
                itemCopy.total = Number(item.id * item.count).toFixed(2);
                cartCopy.push(itemCopy);
            }
            return cartCopy;
        };

        // cart : Array
        // Item : Object/Class
        // addItemToCart : Function
        // removeItemFromCart : Function
        // removeItemFromCartAll : Function
        // clearCart : Function
        // countCart : Function
        // totalCart : Function
        // listCart : Function
        // saveCart : Function
        // loadCart : Function
        return obj;
    }();




        global.displayCart = function() {
            var cartArray = shoppingCart.listCart();
            var output = "";
            var cookievalue = '';

            var d1_out = '13';

            for (var i in cartArray) {
                cookievalue = cookievalue + ',' + cartArray[i].id;

                output += "<tr data-id='" + cartArray[i].id + "'>" +
                    "<td class='py-1 px-2'>" + cartArray[i].id + "</td>" +
                    "<td class='py-1 px-2'>" + cartArray[i].name;

                        if (cartArray[i].d1) {
                            output += "&nbsp;&nbsp;d " + cartArray[i].d1 + " mm";
                        }
                        if (cartArray[i].d2) {
                            output += "&nbsp;&nbsp;d " + cartArray[i].d2 + " mm";
                        }
                        if (cartArray[i].a) {
                            output += "&nbsp;&nbsp;" + cartArray[i].a;
                        }
                        if (cartArray[i].sdr) {
                            output += "&nbsp;&nbsp;SDR " + cartArray[i].sdr;
                        }

                output +=
                    "</dt>" +
                    "<td class='py-1 px-2 w-3'><div class='delete-item ' data-name='" + cartArray[i].name + "' data-id='" + cartArray[i].id + "'>" +
                    "</div></td>" +
                    "</tr>";
            }

            document.cookie = "produkte=" + cookievalue + "; expires=Thu, 01 Jan 2026 00:00:00 UTC; path=/";
            document.querySelector('.show-cart').innerHTML = output;
            document.querySelector('.total-count').innerHTML = shoppingCart.totalCount();


            var totalCountElements = document.querySelectorAll('.total-count');
            for (var j = 0; j < totalCountElements.length; j++) {
                totalCountElements[j].innerHTML = shoppingCart.totalCount();
            }
        }

// *****************************************
// Triggers / Events
// *****************************************

    function init_addtocartbutton() {
        var addToCartButton = document.querySelector('.add-to-cart');
        if (addToCartButton) {
            addToCartButton.addEventListener('click', function (event) {
                event.preventDefault();
                var id = this.getAttribute('data-id');
                var name = this.getAttribute('data-name');

                var d1 = this.getAttribute('data-d1');
                var d2 = this.getAttribute('data-d2');
                var a = this.getAttribute('data-a');
                var sdr = this.getAttribute('data-sdr');

                shoppingCart.addItemToCart(id,name,d1,d2,a,sdr,1);
                displayCart();
            });
        }
    }
    init_addtocartbutton();


    document.querySelector('.show-cart').addEventListener("click", function(event) {
        if (event.target.classList.contains('delete-item')) {
           var id = event.target.getAttribute('data-id');
           shoppingCart.removeItemFromCartAll(id);
            displayCart();
        }
    });

    document.querySelector('.clear-cart').addEventListener('click', function() {
        shoppingCart.clearCart();
        displayCart();
    });

    // Überprüfe, ob der Wert von totalCount im Local Storage vorhanden ist
    if (localStorage.getItem('totalCount')) {
        // Aktualisiere den Wert von totalCount mit dem gespeicherten Wert
        var totalCount = localStorage.getItem('totalCount');
        var totalCountElements = document.querySelectorAll('.total-count');
        for (var i = 0; i < totalCountElements.length; i++) {
            totalCountElements[i].innerHTML = totalCount;
        }

    }


    displayCart();

});



// Äquivalent zur jQuery-Ready-Funktion
document.addEventListener("DOMContentLoaded", function() {
    // Alle Elemente mit dem Klick-Ereignislistener auswählen
    var toggleButtons = document.querySelectorAll(".toggle-password");

    // Schleife über alle ausgewählten Elemente
    toggleButtons.forEach(function(button) {
        // Klick-Ereignislistener hinzufügen
        button.addEventListener("click", function() {
            // Klassenliste des Elements aktualisieren
            if (this.classList.contains("fa-eye")) {
                this.classList.remove("fa-eye");
                this.classList.add("fa-eye-slash");
            } else {
                this.classList.remove("fa-eye-slash");
                this.classList.add("fa-eye");
            }

            // Das zugehörige Eingabeelement abrufen (vorausgesetzt, es folgt direkt im DOM)
            var input = this.nextElementSibling;

            // Den Eingabetyp umschalten
            if (input.getAttribute("type") === "password") {
                input.setAttribute("type", "text");
            } else {
                input.setAttribute("type", "password");
            }
        });
    });
});
